// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["V8zNODH83", "R5c2JOtaQ"];

const serializationHash = "framer-EWLS0"

const variantClassNames = {R5c2JOtaQ: "framer-v-1nipe4e", V8zNODH83: "framer-v-azfljj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Dark: "V8zNODH83", Light: "R5c2JOtaQ"}

const getProps = ({height, id, link, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "V8zNODH83", y2Ro5DaQN: link ?? props.y2Ro5DaQN} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, y2Ro5DaQN, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "V8zNODH83", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={y2Ro5DaQN} openInNewTab={false}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-azfljj", className, classNames)} framer-1ypqecb`} data-framer-name={"Dark"} layoutDependency={layoutDependency} layoutId={"V8zNODH83"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({R5c2JOtaQ: {"data-framer-name": "Light"}}, baseVariant, gestureVariant)}><motion.div className={"framer-g7d91r"} data-framer-name={"Circle"} layoutDependency={layoutDependency} layoutId={"WrYRSPsFR"} style={{backgroundColor: "var(--token-ade0e0d8-65c4-46d7-9134-a0884044fa90, rgb(255, 255, 255))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}} variants={{R5c2JOtaQ: {backgroundColor: "var(--token-118def76-66c1-4361-af6c-b6e3dcb35d14, rgb(0, 0, 0))"}}}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EWLS0.framer-1ypqecb, .framer-EWLS0 .framer-1ypqecb { display: block; }", ".framer-EWLS0.framer-azfljj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 60px; justify-content: center; overflow: visible; padding: 24px; position: relative; text-decoration: none; width: 60px; }", ".framer-EWLS0 .framer-g7d91r { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 10px; justify-content: center; padding: 0px; position: relative; width: 10px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-EWLS0.framer-azfljj, .framer-EWLS0 .framer-g7d91r { gap: 0px; } .framer-EWLS0.framer-azfljj > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-EWLS0.framer-azfljj > :first-child, .framer-EWLS0 .framer-g7d91r > :first-child { margin-left: 0px; } .framer-EWLS0.framer-azfljj > :last-child, .framer-EWLS0 .framer-g7d91r > :last-child { margin-right: 0px; } .framer-EWLS0 .framer-g7d91r > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 60
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"R5c2JOtaQ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"y2Ro5DaQN":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLH7naexzR: React.ComponentType<Props> = withCSS(Component, css, "framer-EWLS0") as typeof Component;
export default FramerLH7naexzR;

FramerLH7naexzR.displayName = "Toggle";

FramerLH7naexzR.defaultProps = {height: 60, width: 60};

addPropertyControls(FramerLH7naexzR, {variant: {options: ["V8zNODH83", "R5c2JOtaQ"], optionTitles: ["Dark", "Light"], title: "Variant", type: ControlType.Enum}, y2Ro5DaQN: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerLH7naexzR, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})