// Generated by Framer (78a4586)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["GoVhUO6tQ"];

const serializationHash = "framer-NfHUH"

const variantClassNames = {GoVhUO6tQ: "framer-v-15lgi3h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, image1, width, ...props}) => { return {...props, HLRV8jgKW: image ?? props.HLRV8jgKW ?? true, NBE030MeX: image1 ?? props.NBE030MeX} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: boolean;image1?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, HLRV8jgKW, NBE030MeX, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "GoVhUO6tQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}>{HLRV8jgKW && (<Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(NBE030MeX)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-15lgi3h", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"GoVhUO6tQ"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-ylyfl3"} data-framer-name={"Fade"} layoutDependency={layoutDependency} layoutId={"GwllYUdxX"} style={{backgroundColor: "rgba(255, 255, 255, 0.5)"}}/></Image></Transition>)}</Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NfHUH.framer-1awlgo6, .framer-NfHUH .framer-1awlgo6 { display: block; }", ".framer-NfHUH.framer-15lgi3h { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 12px; height: 973px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 1200px; }", ".framer-NfHUH .framer-ylyfl3 { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 1px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-NfHUH.framer-15lgi3h, .framer-NfHUH .framer-ylyfl3 { gap: 0px; } .framer-NfHUH.framer-15lgi3h > * { margin: 0px; margin-bottom: calc(12px / 2); margin-top: calc(12px / 2); } .framer-NfHUH.framer-15lgi3h > :first-child { margin-top: 0px; } .framer-NfHUH.framer-15lgi3h > :last-child { margin-bottom: 0px; } .framer-NfHUH .framer-ylyfl3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-NfHUH .framer-ylyfl3 > :first-child { margin-left: 0px; } .framer-NfHUH .framer-ylyfl3 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 973
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"HLRV8jgKW":"image","NBE030MeX":"image1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerloeTFxqXI: React.ComponentType<Props> = withCSS(Component, css, "framer-NfHUH") as typeof Component;
export default FramerloeTFxqXI;

FramerloeTFxqXI.displayName = "Background";

FramerloeTFxqXI.defaultProps = {height: 973, width: 1200};

addPropertyControls(FramerloeTFxqXI, {HLRV8jgKW: {defaultValue: true, title: "Image", type: ControlType.Boolean}, NBE030MeX: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerloeTFxqXI, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})